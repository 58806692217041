<template>
  <transition name="down" appear>
    <div class="transition-wrapper">
      <div class="mark-wrapper">
      </div>
      <div class="mark-content marks">
          <b-container>
            
              <b-row>
                <b-col>
                  <div v-for="(mark, index) in  marks" :key="mark.title.rendered">   
                    <div class="mark" v-if="index == counter || mobile">
                      
                      <h2 v-html='mark.title.rendered'></h2>
                      <img :src="mark.content.rendered">
                                  
                    </div><!--mark-->
                  </div>
                  <router-link to="/canvas">
                    <div class="link-button return-button">
                      Back to Canvas
                    </div>
                  </router-link>
                </b-col>
              </b-row>
            
            <div class="mark-nav-buttons">
                <div class="prev" v-on:click="sliderMinus">
                    <img src="../assets/pre-arrow-black.svg">
                </div>
                <div class="next" v-on:click="sliderPlus">
                    <img src="../assets/next-arrow-black.svg">
                </div>
            </div><!--mark-nav-buttons-->
          </b-container>
        </div>
      </div>
  </transition>
</template>

<script>
import axios from 'axios'

export default {
    props: ['mobile'],
    name: 'Marks',
    components: {
      
    },
    data () {
        return {
            marks: [],
            counter: 0,
            window: {
                width: 0,
                height: 0
            }
        }
    },
    computed: {
      content: function () {
        if (this.marks[0]) {
          return this.contact[0].content.rendered
        } else {
          return ""
        }
      },
      mousePressed: function () {
            if (this.mouseDown > 0 ) {
                return true 
            } else {
                return false
            } 
      }
    },
    methods: {
       sliderPlus: function () {
            let lastSlide = this.marks.length - 1
            this.counter += 1
            if (this.counter > lastSlide) {
                this.counter = 0
            }
        },
        sliderMinus: function () {
            let lastSlide = this.marks.length - 1
            this.counter -=1
            if (this.counter < 0) {
                this.counter = lastSlide
            }
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    },
    created () {
        axios.get('https://wordpress.nikolaigust.de/wp-json/wp/v2/marks/' )
        .then(res => {       
            for(let mark in res.data) {   
              res.data[mark].content.rendered = res.data[mark].content.rendered.replace(/<[^>]+>/g, '') 
              this.marks.push(res.data[mark])
            }   
        })

        this.handleResize()
    },
    destroyed() {

    },
    mounted() {
      
    }
  
}

</script>

<style lang="scss">

.mark-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #ffff9f;
}

.marks {
  .mark {
    @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
  }
}


.mark-content { 
  color: #000;

  @media screen and (max-width: 767px) {
        padding-bottom: 80px;
        padding-top: 27vh;
      }

  .mark{
    z-index: 1;
    position: relative;
    padding: 0;
    background-color: transparent;
    
    @media screen and (max-width: 767px) {
      z-index: 11;
    }

    img {
      max-width: 90vw;
      max-height: 90vh;
      position: fixed;
      top: 50vh;
      left: 50vw;
      transform: translate(-50%, -50%);

      @media screen and (max-width: 767px) {
        position: relative;
        top: 0;
        left: 0;
        max-height: 100%;
        max-width: 100%;
        display: block;
        transform: translate(0, 0);
        border: 2px solid #000;
        background-color: #ffff9f;
        min-width: 100%;
      }

    }

    h2 {
      position: fixed;
      top: 15vh;
      left: 10%;

      @media screen and (max-width: 767px) {
        font-size: 20px;
        position: relative;
        top: 0;
        left: 0;
        display: block;
      }
    }
  }

  a, a:visited {
    color: #000;
    text-decoration: none;

    &:hover {
      color: #000;
      text-decoration: none;
    }
  }

  .link-button {
        padding: 13px;
        text-align: center;
        border: 2px solid #000;
        background-color: #fff;
        margin-bottom: 20px;
        z-index: 1011;
    }

    .return-button {
      //Werte Analog .colors in Component Mark.vue
      position: fixed;
      bottom: 15%;
      left: 10%;

       @media screen and (max-width: 767px) {
        
        bottom: 20px;
        left: 20px;
        margin-bottom: 0;
    }
    }
}

.mark-nav-buttons {
    position: fixed;
    bottom: 15%;
    right: 10%;
    color: #000;
    z-index: 1010;
    cursor: pointer;

    @media screen and (max-width: 767px) {
        display: none;
    }

    a, a:visited {
        color: #000;
        text-decoration: None;

        &:hover {
            color: #fff;
        }
    }

    .next, .prev{
        position: relative;
        display: inline-block;
        max-height: 100px;
        max-width: 100px;
        height: 10vh;
        width: 10vh;
        border: 2px solid #000; 
        background-color: #fff;
        border-radius: 5vh;
        transition: box-shadow 0.3s ease;

        img {
            margin: 18%;
            width: 64%;
        }

        &:hover {
            text-decoration: none;
            box-shadow: 0 0 7em 0 rgba(0, 0, 0, 0.6);
        }
    }

    .prev {
        margin-right: 20px;
    }
}



.down-enter-active,
.down-leave-active {
  transition: all 1s;
}

.down-enter,
.down-leave-to {
  position: absolute;
  top: -100%;
}

.down-leave, 
.down-enter-to {
  position: absolute; 
  top: 0;
}

</style>
