<template>
    <div class="transition-wrapper">
      <div class="mark-wrapper">
      </div>
      <div class="mark-content">
          <b-container>
            <div class="mark">
              <b-row>
                <b-col>
                    <div v-on:click="startDraw()" v-on:touchstart="startDraw()" v-on:mouseup="renderDraw()">
                        <canvas id="canvas" ref="can" v-bind:width="window.width" v-bind:height="window.height"></canvas>
                    </div>
                </b-col>
              </b-row>
            </div><!--mark-->
            <div class="colors">
                <div @click="rot" class="color rot"></div>
                <div @click="blau" class="color blau"></div>
                <div @click="schwarz" class="color schwarz active-color"></div>
            </div><!--colors-->
            <div class="mark-buttons">
                <div id="clear" @click="clear" class="link-button">Clean Up Mess</div> 
                <router-link to="/mark-gallery">
                    <div class="link-button">See Gallery</div>
                </router-link>
         <!--  <div id="download" class="link-button">
                <a
                    className="download-link"
                    href="test"
                    download="myMark.png"
                    @click="downloadCanvas"
                >Download Mark</a>
                </div>--> 
                <div id="publish" @click="exportCanvas" class="link-button">Publish</div>

            </div><!--mark-buttons-->
          </b-container>
        </div>
      </div>
</template>

<script>
import  { fabric } from "fabric";
import axios from 'axios'

export default {
    name: 'Mark',
    components: {
      
    },
    data () {
        return {
            canvas: {},
            window: {
                width: 0,
                height: 0
            },
            mouseDown: 0
        }
    },
    computed: {
      mousePressed: function () {
            if (this.mouseDown > 0 ) {
                return true 
            } else {
                return false
            } 
      }
    },
    methods: {
        downloadCanvas: function(e) {
            e.target.href = this.canvas.toDataURL()
        },
        exportCanvas: function () {
             
            var filePath = this.canvas.toDataURL();
            const d = new Date();
            const year = d.getFullYear().toString()
            const month = function() {
                if ((d.getMonth() + 1) >= 10 ) {
                    return (d.getMonth() + 1)
                } else {
                    return '0' + (d.getMonth() + 1)
                } 
            }
            const day = function() {
                if ((d.getDate()) >= 10 ) {
                    return d.getDate().toString()
                } else {
                    return '0' + d.getDate().toString()
                } 
            }
            const hour = addZero(d.getHours())
            const minutes = addZero(d.getMinutes())
            const seconds = addZero(d.getSeconds())

            function addZero(val) {
                 if (val >= 10 ) {
                    return val
                } else {
                    return '0' + val
                } 
            }
            

            let dateStamp = year + month() + day() + ' - ' + hour + ':' + minutes + ':' + seconds

            const mark = {
                title: dateStamp,
                content: filePath
            }

            const session_url = "https://wordpress.nikolaigust.de/wp-json/wp/v2/marks"

            axios.post(session_url, mark, {
                auth: {
                    username: 'User_Marks',
                    password: 'Ssp5 kdRJ hSBv t1zg RvFh OBnt'
                }
            }).then( res => {
                console.log(res)
                alert('Thankyou! Your Canvas Awaits Permission!')
                window.location.href = "/mark-gallery";
            });

        },
        startDraw: function() {
            if (this.canvas) {
                this.canvas.isDrawingMode = true
                this.canvas.renderAll();
            }    
        },
        renderDraw: function() {
            if (this.canvas) {
                this.canvas.renderAll();
            }
        },
        handleResize() {
            //const can = document.getElementById('canvas')
            this.window.width = window.innerWidth;
            this.window.height = document.querySelector('.mark-wrapper').offsetHeight;

            if (this.canvas) {
                this.canvas.setDimensions({width: this.window.width, height: this.window.height});
                this.canvas.renderAll();
            }

        },
        handleMouseDown () {
            ++this.mouseDown
            this.$store.commit('setMouse', this.mouseDown )
        },
        handleMouseUp () {
            --this.mouseDown
            this.$store.commit('setMouse', this.mouseDown )
        },
        rot (e) {
            this.canvas.freeDrawingBrush.color = '#ff466c'
            let colors = document.getElementsByClassName('color')
            colors.forEach(element => {
                element.classList.remove('active-color')
            });
            e.target.classList.toggle('active-color')
        },
        blau (e) {
            let colors = document.getElementsByClassName('color')
            colors.forEach(element => {
                element.classList.remove('active-color')
            });
            this.canvas.freeDrawingBrush.color = '#003fff'
            e.target.classList.toggle('active-color')
        },
        schwarz (e) {
            let colors = document.getElementsByClassName('color')
            colors.forEach(element => {
                element.classList.remove('active-color')
            });
            this.canvas.freeDrawingBrush.color = '#000'
            e.target.classList.toggle('active-color')
        },
        clear () {
            this.canvas.remove(...this.canvas.getObjects());
        }      
    },
    created () {
       
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
        document.removeEventListener( 'mousedown', this.handleMouseDown);
        document.removeEventListener( 'mouseup', this.handleMouseUp);
    },
    mounted() {
        const ref = this.$refs.can;
   
        this.canvas = new fabric.Canvas(ref, {
            
            lockMovementX: true,
            lockMovementY: true,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true
        });
        fabric.Object.prototype.selectable = false;
        this.canvas.freeDrawingBrush.color = 'black'
        this.canvas.freeDrawingBrush.width = 6  
        
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.startDraw();
        document.addEventListener( 'mousedown', this.handleMouseDown);
        document.addEventListener( 'mouseup', this.handleMouseUp);

    }
}

</script>

<style lang="scss">

.mark-wrapper {
  width: 100vw;
  height: 100%;
  position: fixed;
  background-color: #ffff9f;
}

.canvas-container {
    position: fixed !important;
    top: 0;
    left: 0;
    background-color: transparent !important;
}

canvas {
    position: fixed !important;
    top: 0;
    left: 0;
    cursor: url(../assets/cursor.svg) 0 77, auto !important;
    
}

.mark-content { 
  color: #000;

  .mark{
    
    @media screen and (max-width: 767px) {
        z-index: 11;
    }
    position: relative;
    padding: 0;

    p {
      font-size: 4vh;
      line-height: 6vh;
      margin-bottom: 1em;
      z-index: 100;
    }

  }
}

.colors {
    position: fixed;
    bottom: 15%;
    left: 10%;
    z-index: 1050;

    .color {
        position: relative;
        bottom: -6px;
        display: inline-block;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 2px solid #000;
        margin-right: 15px;

        &:hover {
            cursor: pointer;
        }

        &.active-color {
            border: 2px solid #fff;
        }

        &.rot {
            background-color: #ff466c;
        }

        &.blau {
            background-color: #003fff;
        }

        &.schwarz {
            background-color: #000;
        }
    }

    @media screen and (max-width: 767px) {
        bottom: 60px;
        right: 15px;
    
        .color {
            padding: 8px;
            height: 42px;
            width: 42px;
        }

    }
}

.mark-buttons {
    position: fixed;
    bottom: 15%;
    right: 10%;
    color: #000;
    z-index: 1051;

    @media screen and (max-width: 767px) {
       bottom: 60px;
       right: 15px;
       
       .link-button {
            padding: 8px;
            font-size: 12px;
            line-height: 12px;
            &:hover {
            text-decoration: none;
            color: #000;
            box-shadow: 0 0 8em 0 rgba( 0, 0, 0, 0.6);
        }
       }

    }

    a, a:visited, a:active {
        color: #000;
        text-decoration: None;

        &:hover {
            color: #000;
            text-decoration: none;
        }
    }

    .link-button {
        padding: 13px;
        text-align: center;
        border: 2px solid #000;
        background-color: #fff;
        margin-bottom: 20px;
        z-index: 1010;
        &:hover {
            text-decoration: none;
            box-shadow: 0 0 8em 0 rgba( 0, 0, 0, 0.6);
        }
    }

    #publish {
        background-color: #000;
        color: #fff;
        margin-bottom: 0;
    }



}

</style>
