<template>
    <div class="transition-wrapper">
      <div class="contact-wrapper">
      </div>

      <div class="contact-content">
          <b-container>
            <div class="contact content">
              <b-row>
                <b-col>
                  <div v-html='content'></div>
                </b-col>
              </b-row>
            </div><!--contact-->
          </b-container>
        </div>
      </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Contact',
    components: {
      
    },
    data () {
        return {
           contact: []
        }
    },
    computed: {
      content: function () {
        if (this.contact[0]) {
          return this.contact[0].content.rendered
        } else {
          return ""
        }
      }
    },
    created () {
        axios.get('https://wordpress.nikolaigust.de/wp-json/wp/v2/pages?slug=contact' )
        .then(res => {          
            this.contact = res.data
        })
    }
}

</script>

<style lang="scss">

.contact-wrapper {
  width: 110%;
  height: 100%;
  position: fixed;
  background-color: #000;
  overflow: hidden;
}

.contact-content { 
  
  color: #fff;

  .contact {
    z-index: 1001;
    position: relative;

    @media screen and (max-width: 767px) {
      padding-top: 27vh;
      max-height: auto;
      transform: none;
      margin-top: 0;
    }

    p {
      font-size: 4vh;
      line-height: 6vh;
      margin-bottom: 1em;
      z-index: 100;
    }

    a {
        color: #fff;
        text-decoration: underline;
        &:visited {
            color: #fff;
        }

        &:hover {
            text-decoration: none;
        }
    }
  }
}

</style>
